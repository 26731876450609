import React from "react"
import { useStaticQuery, Link, graphql } from "gatsby"
import Footer from "../components/footer"

// Construct the NavItem element
const NavItem = props => (
  <li>
    <Link to={props.to}>{props.children}</Link>
  </li>
)
// Get Site Meta Data from gatsby-config.js
export default ({children}) => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
  )
  // Display the header and layout
  return (
  <div>
    <header>
      <h1 className="logo">
        <Link to="/">{data.site.siteMetadata.title}</Link>
      </h1>      
      <nav>
        <ul className="menu">
          <NavItem to="/about/">About</NavItem>
          <NavItem to="/work/">Work</NavItem>
          {/* <li><a href="/blog/">Blog</a></li> */}
          <li>
            <a href="mailto:jeff@jayjodesign.com">Contact Me</a>
          </li>
        </ul>
      </nav>
    </header>
    {children}
    <Footer></Footer>
  </div>
  )
}