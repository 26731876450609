import React from "react"

const FooterLink = props => (
  <a href={props.linkTo} target="_blank" rel="noopener noreferrer">{props.linkText}</a>
)

export default (props) => (
  <footer>
    <p className="copyright">
      <span>&copy; 2020 | Made with love in Denver</span>
      <FooterLink
        linkTo="https://twitter.com/jayjo"
        linkText="Twitter"
      />
      <FooterLink
        linkTo="https://dribbble.com/jayjo"
        linkText="Dribbble"
      />
      <FooterLink
        linkTo="https://github.com/jayjo"
        linkText="Github"
      />
      <FooterLink
        linkTo="https://instagram.com/jayjo44"
        linkText="Instagram"
      />
      <FooterLink
        linkTo="https://www.linkedin.com/in/jayjo/"
        linkText="LinkedIN"
      />
    </p>
    <script type="text/javascript" src="/scripts/vendors/plugins.js"></script>
    <script type="text/javascript" src="/scripts/js/global.js"></script>
  </footer>
)